
import './Partners.scss'
import waveLogo from '../../assets/img/wave.webp';
import orangeMoney from '../../assets/img/orangeMoney.png';
import vcards from '../../assets/img/vcards.png';
import paydunya from '../../assets/img/paydunya.png';

const Partners = () => 
{
	return(
		<div className="PartnersWrapper">
				<img src={waveLogo} alt="waveLogo" />
				<img src={orangeMoney} alt="orangeMoney" />
				<img style={{borderRadius: 0, width: 250}} src={vcards} alt="vcards" />
				<img src={paydunya} alt="paydunya" />
		</div>
	)
}

export default Partners