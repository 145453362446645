
import { useState } from 'react'
import './Navbar.scss'
import googlePlay from '../../assets/img/google-play.png';
import appStore from '../../assets/img/app-store.png';
import up from '../../assets/img/up-arrow.png';


const Navbar = ({modalVisibilily, setModalVisibilily}) => 
{
	const [drawerVisibility, setDrawerVisibility] = useState(false);
	const handleInsideNavigation = (key) => {
		let url;
		if (key == 'contacts') {
			url = "https://naanocorp.com/contact-us";
		}
		if (key == 'aboutus') {
			url = "https://naanocorp.com/about-us/";
		}
		const a = document.createElement('a');
		a.href = `${url}`;
		a.target = '_blank';
		document.querySelector('.generated').appendChild(a);
		a.click();
		a.remove();
	}
	return(
		<div className="NavbarWrapper">
			<div className='backtoTop'>
				<a href='#goTop'>
					<img src={up} alt="Arrow up" />
				</a>
			</div>
			<div className='generated'></div>
			<div className='logo'>
				SENCRYPT |
			</div>
			<div></div>
			<div className='menuContent'>
				<div onClick={()=>{handleInsideNavigation('contacts')}}>
					Contactez-nous
				</div>
				<div onClick={()=>{handleInsideNavigation('aboutus')}}>
					A propos de nous
				</div>
				<div className='callToActionDiv' onClick={()=>{setModalVisibilily(true)}} >
					Acheter Maintenant
				</div>
			</div>
			<div className='mobileMenu'>
				<div className='drawerContainer'>
						<svg viewBox="0 0 24 24" onClick={()=>{setDrawerVisibility(true)}} viewBox="0 0 24 24">
							<path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
						</svg>
				</div>
				{
					drawerVisibility &&
					<div className='drawer2'>
						<div className='close'>
								<svg onClick={()=>{setDrawerVisibility(false)}} viewBox="0 0 24 24">
									<path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
								</svg>
						</div>
						<div className='menuTitle'>
							Menu
						</div>
						<div className='menuContentM'>
							<div onClick={()=>{handleInsideNavigation('contacts')}}>
								<svg viewBox="0 0 24 24">
									<path fill="currentColor" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M10,17L15,12L10,7V17Z" />
								</svg>
								Contactez-nous
							</div>
							<div onClick={()=>{handleInsideNavigation('aboutus')}}>
								<svg viewBox="0 0 24 24">
									<path fill="currentColor" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M10,17L15,12L10,7V17Z" />
								</svg>
								A propos de nous
							</div>
							<div className='callToActionDiv' onClick={()=>{setDrawerVisibility(false);setModalVisibilily(true)}}>
								Acheter Maintenant
							</div>
						</div>		
					</div>	
				}										
			</div>
			{
				modalVisibilily &&
				<div className='modal'>
						<div className='backdrop'></div>
						<div className='modal-content-1'>
							<div className='close'>
									<svg onClick={()=>{setModalVisibilily(false)}} viewBox="0 0 24 24">
										<path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
									</svg>
							</div>
							<div className='content'>
								<div>
									<img src={googlePlay} alt="googlePlay" />
								</div>
								<div style={{borderLeft:'white 2px dashed'}}>
									<img src={appStore} alt="appStore" />
									<span style={{fontWeight: 'bold', fontSize: 22, marginTop: 10}}>Bientot</span>
								</div>
							</div>
						</div>
				</div>
			}	
		</div>
	)
}

export default Navbar