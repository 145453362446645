//import * as visitorsTypes from '../constants/visitors.types.js'

const initialState = {
    
}

export const visitorsReducer = (state = initialState, action) => {

    let copyState = {...state} ;

    switch (action.type)
    {
        
    }
    return copyState;
}