
import './Footer.scss'


const Footer = () => 
{
	return(
		<div className="FooterWrapper">
			 <div className='container'>
					<div className='about'>
							<div className='title'>
								SENCRYPT
							</div>
							<div className='content'>
								Facilite l'accès au nouvelles technologies notamment la Blockchain à 
								toute personne intéréssée.
							</div>
					</div>
					<div className='other' style={{alignItems: 'flex-start'}}>
							<div className='title'>
								Contactez nous
							</div>
							<div className='content'>
								contact@sencrypt.tech
							</div>	
					</div>
					<div className='other'>
							<div className='title' style={{width: "100%"}}>
								Newsletter
							</div>
							<div className='content' style={{width: '100%'}}>
								<div className='customNewsLetterInput'>
									<div className='input'>
										<input/>
									</div>
									<div className='btn'>
										<button>S'inscrire</button>
									</div>									
								</div>
							</div>	
					</div>
			 </div>
			 <div className='copyRight'>
				Tous droits réservés à <a target="_blank" href='https://naanocorp.com'>Naanocorp</a>
			 </div>
		</div>
	)
}

export default Footer