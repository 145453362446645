
import './Definitions.scss'
import earnPlay from '../../assets/img/earnPlay.png';
import twistedArrow from '../../assets/img/twistedArrow.png';
import nft from '../../assets/img/nft.png';

const Definitions = () => 
{
	return(
		<div className="DefinitionsWrapper">
			<a name="whatscrypto" />
			<div className='container'>
				<div className='imgDiv'>
					<img src={earnPlay} alt="earnPlay" />
				</div>
				<div className='definitions'>
						<h1>
							Qu'est-ce-que la <span> cryptomonnaie ? </span>
						</h1>
						<h2> 
							Il s'agit de l'argent décentralisé, indépendant de tout gouvernement et que tout un chacun 
							peut obtenir et disposer selon son bon vouloir. <br/>
							Il est important de noter que cet argent est gardé de manière sécurisé sur le système le moins
							violable du monde qui garantie son intégrité et des transactions complètement anonymes.
						</h2>
				</div>
			</div>
			<div className='break'>
				<a href='#whatsnft'><img title='Clique moi' className='floatingAnimation' src={twistedArrow} alt="twistedArrow" /></a>
			</div>
			<a name="whatsnft" />
			<div className='container reversed'>				
				<div className='imgDiv'>
					<img src={nft} alt="nft" />
				</div>
				<div className='definitions' style={{direction: 'ltr'}}>
						<h1>
							Qu'est-ce-qu'un <span> NFT ? </span>
						</h1>
						<h2> 
							Très simple, c'est toute information qui est stockée sur une <a target="_blank" href='https://fr.wikipedia.org/wiki/Blockchain'>base de donnée décentralisée</a>.<br></br><br></br>
							Ce qui fait que cette information devient unique au monde et non falsifiable. Cela peut être une image, une musique, un document en word ou pdf... Cette information appartient de manière unique à une personne ou entité.
						</h2>
				</div>
			</div>
		</div>
	)
}

export default Definitions