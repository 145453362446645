
import Footer from "../../components/Footer/Footer";
import LearnMore from "../../components/LearnMore/LearnMore";
import Navbar from "../../components/Navbar/Navbar";
import Presentation from "../../components/Presentation/Presentation";
import Partners from "../../components/Partners/Partners";
import Definitions from "../../components/Definitions/Definitions";
import './Welcome.scss'
import { useState } from "react";


const Welcome = () => 
{
	const [modalVisibilily, setModalVisibilily]   = useState(false);
	return(
		<div className="WelcomeWrapper">
			<Navbar modalVisibilily={modalVisibilily} setModalVisibilily={setModalVisibilily} />
			<Presentation/>
			<Partners/>
			<Definitions/>
			<LearnMore setModalVisibilily={setModalVisibilily} />
			<Footer/>
		</div>
	)
}

export default Welcome