
import './Presentation.scss'
import cards from '../../assets/img/cards.png'
import shootingStars from '../../assets/img/shooting-stars.png'

const Presentation = () => 
{
	return(
		<div className="PresentationWrapper">
			 <div className='leftContent'>
				 <img src={shootingStars} alt="shootingStars" />
				<h1>
					Bienvenue dans le <span>Futur</span>...
				</h1>
				<h2>
					Amateurs et curieux ou même professionnels de l'univers des cryptomonnaies et NFTs ?
				</h2>
				<h2>
					Prenez un cookie et suivez nous dans ce nouveau voyage.
				</h2>
				<div className='callToActionDiv'>
					<a href='#whatscrypto'>Commencer !</a>
				</div>
				<div className='stats'>
					<h1>
						2M+ <span>Utilisateurs actifs</span>
					</h1>
					<h1 className='customH1' style={{borderLeft:'solid 3px white'}}>
						4.8 <span>Note moyenne</span>
					</h1>
				</div>
			 </div>
			 <div className='rightContent'>
					<img className='floatingAnimation' src={cards} alt='Cards' />
			 </div>
		</div>
	)
}

export default Presentation