import Welcome from "./pages/Welcome/Welcome"
import './App.scss';

function App() {
  return (
    <div id="app" className="App">
      <a name="goTop"></a>
      <Welcome/>
    </div>
  );
}

export default App;
