
import './LearnMore.scss'

const LearnMore = ({setModalVisibilily}) => 
{
	return(
		<div className="LearnMoreWrapper">
			<div className='container'>
					 <div className='left'>
						 <h1>
							 Enregistrez vous dès maintenant sur l'application et intégrez une newsletter Blockchain privée.
						 </h1>
						 <button className='callToAction' onClick={()=>{setModalVisibilily(true)}}>
							Commencer maintenant !
						 </button>
					 </div>
					 <div className='right'></div>
			</div>
		</div>
	)
}

export default LearnMore